<!--Ez az Admin login-->
<template class="my-auto">
  <div class="text-center center py-auto d-flex">
    <v-card outlined elevation="2" min-width="30%" class="m-auto p-2" border>
      <p class="display-1 my-3">{{ lang.tanulmanyi_tajekoztato }}</p>
      <p class="display-1 my-3">{{ lang.admin_felulet }}</p>
      <v-divider class="mx-2 uni_blue2 size"></v-divider>
      <v-text-field
        :label="lang.login_felhasznalonev"
        class="m-3"
        color="uni_blue2"
        :error-messages="errors.userName"
        v-model="user.userName"
        v-on:keyup.enter="Login"
      />
      <v-text-field
        :label="lang.login_jelszo"
        class="m-3"
        color="uni_blue2"
        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
        :type="show ? 'text' : 'password'"
        @click:append="show = !show"
        :error-messages="errors.password"
        v-model="user.password"
        v-on:keyup.enter="Login"
      />
      <v-alert dense outlined type="error" v-if="alert">
        {{ lang.nem_megfelelo_hitelesitesi_adat }}
      </v-alert>
      <v-row>
        <v-col :cols="12" :lg="6" :md="12">
          <v-btn 
            dark
            class="m-3" 
            color="uni_blue" 
            @click="Login">{{
            lang.bejelentkezes
          }}</v-btn>
        </v-col>
        <v-col :cols="12" :lg="6" :md="12">
          <v-btn
            class="my-4 float-right"
            color="uni_blue"
            @click="languageChange('en')"
            elevation="0"
            plain
            >en</v-btn
          >
          <v-btn
            class="my-4 float-right"
            color="uni_blue"
            @click="languageChange('hu')"
            elevation="0"
            plain
            >hu</v-btn
          >
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import hu from "@/plugins/hu.json";
import en from "@/plugins/en.json";
export default {
  data: () => ({
    show: false,
    user: {
      userName: "",
      password: "",
    },
    errors: {
      userName: "",
      password: "",
    },
    lang: hu,
    langName: "hu",
    alert: false,
  }),

  methods: {
    async Login() {
      this.alert = false;
      this.errors = {
        userName: "",
        password: "",
      };
      try {
        const response = await this.$http.post("/login", this.user);
        if (Object.keys(response.errors).length > 0) {
          this.errors = response.errors;
        } else {
          if (response.user === false) {
            this.alert = true;
          } else {
            this.$cookies.set("adminToken", response.user.token, 0);
            this.$store.commit('setUser', response.user)
            this.$router.push("/admin");
          }
        }
      } catch (e) {
        console.error(e);
      }
    },
    languageChange(type) {
      if (type == "en") {
        this.lang = en;
        this.langName = "en";
      } else {
        this.lang = hu;
        this.langName = "hu";
      }
      this.$cookies.set("lang", type);
    },
  },
};
</script>

<style>
.center {
  min-height: 100%;
}
.margin {
  margin: auto;
}
.size {
  font-size: 30px;
}
</style>